@import "shared/assets/scss/variables.scss";

.dropdown-container {
  position: relative;
  width: 100%;
  .no-error {
    border: 0.1rem solid $primary-bg-color;
  }
  .error {
    border: 0.1rem solid $red-notification-color;
  }
  .error-color {
    color: $red-notification-color;
    margin-top: 0.6rem;
    font-size: 1.2rem;
    font-family: "Mon-medium";
  }
  .select-span {
    height: 4rem;
    border: 0.1rem solid #c1c9d2;
    border-radius: 0.8rem;
    box-sizing: border-box;
    border-radius: 0.8rem;
    padding: 1.15rem 0.45rem 1.15rem 1.6rem;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name-container {
      width: inherit;
      overflow: hidden;
    }
    .selected-value {
      font-family: "Mon-medium";
      color: $grey-text-color;
      font-size: 1.4rem;
      line-height: 1.7rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .placeholder {
      color: #333;
      opacity: 0.4;
    }

    .select-box-down-arrow-rotate {
      transform: rotate(180deg);
      transition: transform 0.3s linear;
    }
    .select-box-down-arrow-unrotate {
      transform: rotate(0);
      transition: transform 0.3s linear;
    }
    .icon-container {
      background: $grey-bg-color;
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .select-box-down-arrow {
        cursor: pointer;
        padding: 0.7rem;
      }
    }
  }
  .red-border {
    border: 0.1rem solid #ff5757;
  }
  .error-wrap {
    color: #ff5757;
    font-family: "Mon-medium";
    font-size: 1.4rem;
    margin: 0.6rem 0;
  }
  .option-box-wrapper {
    border: 0.1rem solid #c1c9d2;
    border-radius: 0.8rem;
    position: absolute;
    top: 4.2rem;
    padding-top: 1rem;
    width: 99%;
    background-color: white;
    z-index: 2;
    animation: openDrop 0.2s forwards;

    @keyframes openDrop {
      from {
        opacity: 0.2;
      }
      to {
        opacity: 1;
      }
    }
    ul {
      li {
        cursor: pointer;
        padding: 1rem;

        &:hover {
          background-color: $grey-bg-color;
        }

        .selected {
          color: $primary-bg-color;
        }
        .option {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.4rem;
        }
      }
    }
  }
}
