.toggleContainer{
  display: flex;
  font-family: "Mon-medium", sans-serif;
  font-size: 1.6rem;
  line-height: 1.7rem;

  .toggleTitle{
    margin: 0 1.2rem 0 0 !important;
  }
  .toogle-title-right{
    margin: 0 0 0 1.2rem !important;
  }

  .active{
    color: #1AA99C;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 3.6rem;
    height: 1.6rem;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: .1rem solid #CCCCCC;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 1.2rem;
    width: 1.2rem;
    left: .2rem;
    bottom: .1rem;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: #1AA99C;
  }
  
  
  input:checked + .slider:before {
    -webkit-transform: translateX(1.8rem);
    -ms-transform: translateX(1.8rem);
    transform: translateX(1.8rem);
  }
  
  .slider.round {
    border-radius: 3.4rem;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
}
