@import "shared/assets/scss/variables.scss";

.navigator-manager-patient-call-table {
  .inbound-navigator-dashboard-table-header {
    margin-bottom: 0;
    display: table;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    box-shadow: unset;

    &-content {
      display: flex;
      align-items: center;
      width: fit-content;
      .name {
        margin-right: 8px;
      }
      &:hover {
        cursor: pointer;
      }
    }

    &.hidden {
      display: none;
    }

    tr {
      font-weight: bold;
      color: $grey-text-color;
      background: rgb(241, 250, 249);
      border-radius: 0.8rem 0.8rem 0 0;
      border-style: hidden;
      box-shadow: 0 0 0 0.1rem $primary-border-color;

      th {
        text-align: left;
        font-size: 1.4rem;
        padding: 1.5rem 2rem 1.5rem 1.5rem;
        border: 0.1rem solid $primary-border-color;
        border-style: solid none solid none;
        font-family: "Mon-bold", sans-serif;

        .table-header-content {
          display: flex;
          align-items: center;
          &-name {
            margin-right: 0.9rem;
          }
        }
      }
      th:nth-child(1) {
        border-left: 0.1rem solid $primary-border-color;
        border-radius: 0.8rem 0 0 0;
        width: 5%;
      }
      th:nth-child(2) {
        width: 25%;
      }
      th:nth-child(3) {
        text-align: left;
        width: 20%;
      }
      th:nth-child(4) {
        text-align: left;
        width: 10%;
        padding-left: 5px;
      }
      th:nth-child(5) {
        text-align: left;
        width: 15%;
        padding-left: 7px;
      }
      .missed-call-reason {
        padding-left: 3px;
      }
      th:last-child {
        border-style: solid solid solid none;
        width: auto;
      }
    }
  }

  tbody > tr > td {
    padding: 0;
  }

  .dashboard-no-border-in-mobile {
    box-shadow: unset;
  }

  .infinite-scroll-component__outerdiv {
    width: 100%;
    border-radius: 0 0 0.8rem 0.8rem;
    box-sizing: border-box;
    box-shadow: 0 0 0 0.1rem #eef0f8;
    height: calc(100vh - 23rem) !important;
    .infinite-scroll-component {
      width: 100%;
      background-color: white;
      overflow-y: overlay !important;
    }
  }

  .inbound-navigator-dashboard-table-body {
    .loading-container {
      height: calc(100vh - 26rem);
    }
    .no-message-container {
      position: relative;

      .no-message-td {
        width: 100%;
        height: 5rem;
        border-style: none;
      }

      .no-messages {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
      }
    }
    tr {
      background-color: #ffffff;
      &.not-read {
        color: #b71422;
        background-color: #ffeeee;
      }

      &.missed {
        color: #b71422;
      }

      &.night-border {
        background: #fbede1;
        border: 0.2rem solid white;
      }

      .outgoing-icon {
        line-height: 37px;
        .outgoing-call-icon {
          transform: translateY(5px);
        }
      }

      &.green-ongoing-color {
        color: #00887c;
        & > * {
          font-family: "Mon-bold", sans-serif;
        }
      }

      &.green-border {
        background: #f0f9fa;
        border: 0.2rem solid white;
      }

      &.purple-border {
        border: 0.2rem solid white;
        background: #f7f5ff;
      }

      td {
        font-size: 1.4rem;
        line-height: 1.6rem;
        padding: 1.5rem 2rem 1.5rem 1.5rem;
        text-align: left;
        font-family: "Mon-medium", sans-serif;

        &.night-border {
          border-bottom: 0.2rem solid $night-border;
        }

        a {
          color: $primary-text-color;
          cursor: pointer;
        }

        span {
          .bold-text {
            font-weight: bold;
            color: #1aa99c;
          }
        }
      }

      td:nth-child(1) {
        text-align: center;
        width: 5%;
      }

      td:nth-child(2) {
        width: 25%;
        cursor: pointer;
      }

      td:nth-child(3) {
        width: 20%;
        .date-time-container {
          display: flex;
          align-items: center;

          .night-icon {
            cursor: pointer;
            margin-left: 1.7rem;
          }
        }
      }
      td:nth-child(4) {
        width: 10%;
      }
      td:nth-child(5) {
        width: 15%;
      }

      td:last-child {
        width: auto;
        border-style: none none solid none;
        &.no-padding {
          padding-left: 0px;
          padding-right: 0px;
        }
        .icons {
          &.visible {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
          }

          &.hidden {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.1s ease-in-out;
          }

          .icon-background {
            height: 3.2rem;
            width: 3.2rem;
            background-color: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.message {
              margin-left: 1.4rem;
            }
          }
        }
      }

      .participants-section {
        display: flex;
        flex-direction: row;
        align-items: center;

        .participant-info-button {
          margin-left: 1.2rem;
          display: inline-block;
        }

        .participant-type {
          width: fit-content;
          background-color: $white-2;
          padding: 0.2rem 0.8rem;
          margin-left: 0.8rem;
          border-radius: 0.4rem;
          opacity: 0.7;

          .type-text {
            text-align: center;
            color: $grey;
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
          }
        }
      }

      .missed {
        color: $missed-call-color;
      }
    }
    .hovered {
      background: #eeeeee;
    }
  }

  .navigator-dashboard-mobile-data {
    .infinite-scroll-component__outerdiv {
      height: calc(100vh - 30rem) !important;
      box-shadow: none;
      border-radius: 0;
      .infinite-scroll-component {
        &::-webkit-scrollbar-thumb {
          background: $white;
        }
      }
    }
    .inbound-navigator-dashboard-table-body {
      tr {
        td {
          width: auto !important;
          border: 0;
          padding: 2.4rem 0.6rem;
          text-align: left;
          vertical-align: top;
          font-size: 1.4rem;

          .empty-call-duration {
            margin-right: 0.7rem;
          }

          &:first-child {
            width: 2rem !important;
            text-align: left;
            padding-left: 1rem;
            padding-right: 1rem;
          }

          &:last-child {
            text-align: right;
            word-break: break-word;
            width: 30% !important;
          }

          .navigator-date {
            line-height: 1.7rem;
            color: $grey-1;
            margin-top: 1rem;
            font-size: 1.2rem;
          }

          .participant-info-button {
            width: 100%;
            display: block;
            margin-top: 1rem;
          }
        }

        &:first-child {
          td {
            // padding-top: 0;
          }
        }

        &:last-child {
          box-shadow: none;
          border-style: unset;
        }

        &:not(:last-child) {
          border-bottom: 0.1rem solid #f3f2f9;
        }

        .participants-section {
          flex-wrap: wrap;
          flex-direction: column;
          align-items: start;

          .participant-info-button {
            margin-left: 0;
            width: 100%;
            display: block;
            margin-top: 1rem;
          }

          .participant-type {
            margin-left: 0;
            margin-top: 0.8rem;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

#modal {
  .participants-call-popup {
    .side-popup-content {
      border-top-left-radius: 1.3rem;
      border-top-right-radius: 1.3rem;
      padding: 3rem 1.8rem;
      height: auto;
      width: 100%;
    }

    .modal-header {
      display: none;
    }

    .modal-body {
      text-align: center;
    }

    .call-button {
      font-family: "Mon-semi-medium", sans-serif;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 0.8rem;
      min-height: 5rem;
      margin-top: 3.3rem;
      font-size: 1.6rem;
      .phone-call-icon {
        stroke-width: 45;
      }
    }

    .participants-name {
      font-family: "Mon-medium", sans-serif;
      font-weight: bold;
      font-size: 2.2rem;
      line-height: 2.7rem;
      color: $grey-text-color;
    }

    .participants-call-duration {
      font-family: "Mon-medium", sans-serif;
      font-weight: 500;
      margin-top: 0.4rem;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: $grey-1;
    }
    &.mobileView {
      .side-popup-content {
        height: auto;
      }
    }
  }
}
.action-table {
  position: absolute;
  left: 0;
  height: 100%;
  margin-top: 0px;
  top: 0;
  width: 100%;
  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }
  &.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  .action-row {
    box-shadow: none !important;
    width: 100%;
    height: 100%;
    display: flex;
    .empty-filling,
    .action-col {
      padding: 8px 46px 8px 22px;
    }
    .empty-filling {
      width: 85%;
    }
    .action-col {
      text-align: right;
      width: 15%;
      height: 100%;
      padding-right: 0;
      box-sizing: border-box;
      height: 100%;
      background: linear-gradient(270deg, #c4cdcb 22.29%, rgba(238, 238, 238, 0) 100%);
      position: relative;
      .row-icon-container {
        position: relative;
        width: 100%;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: end;
      }
    }
  }
}
