@import "shared/assets/scss/variables.scss";

#profile-container {
  height: 100%;
  overflow-y: auto;
  .breadcrumb-container {
    margin-bottom: 2.3rem;
  }
  .profile-header {
    .desktop-profile-title {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: 4rem;
    }
    .header-title {
      font-family: "Mon-bold", sans-serif;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: $grey-header-color;
    }
    .mobile-profile-title {
      display: flex;
      align-items: center;
      font-family: "Mon-medium", sans-serif;
      font-weight: 600;
      font-size: 2.2rem;
      line-height: 2.7rem;
      text-align: center;
      color: #3d3d3d;
      width: 100%;
      margin-top: 0.8rem;
      margin-bottom: 3.6rem;
      .back-to-home-icon {
        margin-right: 0.5rem;
        cursor: pointer;
        path {
          stroke: #3d3d3d;
        }
      }
      .mobile-profile-text {
        color: $grey-text-color;
        display: block;
        width: 100%;
      }
    }
    .save-changes {
      border-radius: 0.8rem;
      flex: 0 0 17.3rem;
      height: 4rem;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $white;
      cursor: pointer;
    }
    .new-navigator-button {
      width: 19.7rem;
      height: 4rem;
      border-radius: 0.8rem;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $white;
    }
  }

  .filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tab-section {
      margin-bottom: 1.6rem;
      position: relative;
      display: inline-flex;
      .vacation-mode-indicator {
        position: absolute;
        top: 43%;
        left: 45%;
      }
    }
  }

  .content {
    background: #ffffff;
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
    border-radius: 0.8rem;
    padding: 3.5rem;
    display: flex;

    .loading-container {
      margin: auto;
    }

    &.profile-content {
      min-height: 70vh;
      width: inherit;
      min-width: fit-content;
      #date-picker-selector {
        z-index: unset;
      }
      .no-profile {
        width: 100%;
        text-align: center;
        margin: auto auto;
        font-family: "Mon-bold", sans-serif;
        font-size: 2rem;
        color: #9da0a7;
      }
    }

    &.new-navigator-content {
      height: 77%;

      &.no-profile {
        width: 100%;
        text-align: center;
        margin: auto auto;
        font-family: "Mon-bold", sans-serif;
        font-size: 2rem;
        color: #9da0a7;
      }
    }

    .profile-note {
      margin-bottom: 2.55rem;
    }

    .on-off-toggle {
      margin-bottom: 3.35rem;
    }

    .low-opacity {
      opacity: 0.4;
      cursor: not-allowed;
    }

    #date-picker-container {
      left: -228px;
      right: inherit;
      top: -45rem;
    }

    &.vacation-mode {
      padding: 3rem 3rem 2rem 3.2rem;
      .vacation-container {
        width: 100%;
        overflow: auto;
      }
    }
    .empty-profile-image {
      height: 13.9rem;
      width: 13.9rem;
    }
    .upload-text {
      cursor: pointer;
    }
    .dropdown-label {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #333333;
      text-transform: capitalize;
      margin: 0.6rem 0;

      &.backup-navigator-label {
        margin-top: 3rem;
      }
      .optional-text {
        color: gray;
        margin-left: 4px;
      }
    }
    .proxy-user-dropdown-container {
      width: 24.2rem;
    }

    .backup-navigator-dropdown-container-profile {
      width: 50%;
      min-width: 583px;
      .search-box.icon-button {
        width: fit-content;
      }
      .backup-navigator-dropdown {
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        .text {
          font-size: 1.4rem;
        }
      }
      .option-box-wrapper {
        top: 100%;
        right: 0;
        height: fit-content;
        padding: 0.5rem 0;
        max-height: 23rem;
        width: 99.5%;
      }

      .search-items {
        font-size: 1.4rem;
        padding: 1.5rem;
      }
      .display-number-of-list-icon {
        font-size: 1.4rem;
        background: #1aa99c;
        padding: 0.6rem 1.5rem;
        color: white;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        font-family: "Mon-medium";
        white-space: nowrap;
        border-radius: 4.8rem;
        outline: none;
        border: none;
      }
    }
    .input-section {
      margin-left: 5rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 5rem 2rem;
      & > div {
        margin-bottom: 20px;
        & > .text-input-field-container {
          margin-bottom: 0 !important;
          width: 30%;
        }
      }
      &-groups {
        display: flex;
        justify-content: space-between;
        &.vacation-proxy-navigator-input-container {
          justify-content: unset;
          gap: 10rem;
        }
      }
      &-groups-2 {
        display: flex;
        & > div:first-child {
          margin-right: 2.5rem;
        }
      }
      .first-name-input,
      .last-name-input,
      .email-input {
        width: 100%;
      }
      .text-input-field-container {
        .field-wrap {
          display: block;
          width: 100%;
        }
      }
      .width30 {
        width: 30%;
      }
      .width65 {
        width: 65%;
      }
      .phone-container {
        width: 30%;
        .error-wrap {
          color: $red-notification-color;
          margin-top: 0.6rem;
          font-size: 1.2rem;
          font-family: "Mon-medium", sans-serif;
        }
        .phone-container-content {
          margin-bottom: 0;

          .field-wrap {
            display: flex;
          }
        }
      }
      .work-hours-container {
        display: flex;
        &-group {
          display: flex;
          align-items: center;
          margin-right: 1rem;
          & label {
            font-family: "Mon-medium", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            color: #6b727f;
            margin-right: 1rem;
          }
          .workhour-dropdown-container {
            width: 10.85rem;
          }
          .workhour-dropdown {
            max-height: 16rem;
            overflow-y: auto;
          }
          & input {
            color: $grey-text-color;
            font-size: 1.4rem;
            line-height: 17px;
            background: #ffffff;
            mix-blend-mode: normal;
            box-sizing: border-box;
            border-radius: 8px;
            border: 1px solid $primary-input-border;
            margin-left: 0px;
            font-family: "Mon-medium", sans-serif;
            white-space: nowrap;
            margin-left: 0.5rem;

            &:nth-child(2) {
              width: 108px;
              padding: 11.5px 12px;
            }
            &:last-child {
              font-size: 1.4rem;
              width: 44.5px;
              height: 100%;
              padding: 10px;
              &:hover {
                cursor: pointer;
              }
            }
            &::placeholder {
              color: $grey-text-color;
            }
          }
        }
      }
      .vacation-date-container {
        display: flex;
        gap: 10rem;
        align-items: center;
        min-width: unset;
        justify-content: space-between;
        .on-off-toggle {
          margin: 0;
          height: 100%;
          min-width: fit-content;
          & > .dropdown-label {
            margin-bottom: 2rem;
          }
          .toggleTitle {
            margin: 0;
          }
        }
      }
      .navigator-dropdown-container {
        display: inline-flex;
        flex-direction: column;
        .navigator-selection {
          width: 100%;
        }
        .navigator-dropdown {
          width: 99%;
          overflow: auto;
          height: auto;
        }
      }
      .time-zone-dropdown-container {
        display: inline-flex;
        flex-direction: column;
        .time-zone-selection {
          margin-top: 2px;
          height: 42px;
        }
        .time-zone-dropdown {
          width: 99%;
          max-height: 110px;
          overflow-y: auto;
        }
      }
      .assigned-phone-number-dropdown-container {
        display: inline-flex;
        flex-direction: column;
        width: 100%;

        .assigned-phone-number-selection {
          width: 100%;
          height: 100%;
          padding: 0.5rem;
          .text {
            font-size: 1.4rem;
          }
          .search-box-wrapper {
            width: 23rem;
          }
          .search-box.icon-button {
            width: 100%;
            background: transparent;
            .input-plus-lens {
              width: 100%;
              input {
                width: 100%;
              }
            }
          }
        }
        .assigned-phone-number-dropdown {
          max-height: 110px;
          overflow-y: auto;
          height: fit-content;
          width: 99.5%;
          right: 0;
          top: 100%;
        }
        .options-box {
          ul {
            li {
              font-size: 1.4rem;
              font-family: "Mon-Medium", sans-serif;
              &:last-child {
                border-bottom: unset;
              }
              p:first-child {
                margin-bottom: 0.2rem;
                font-weight: bold;
              }
              p:last-child {
                color: #111111;
              }
            }
          }
        }
        .search-items {
          padding: 1.5rem;
          &-left {
            &__first {
              margin-bottom: 0.2rem;
              font-weight: bold;
            }
            &__second {
              color: #111111;
            }
          }
          &-right {
            div:first-child {
              margin-bottom: 0.2rem;
              font-size: 1.2rem;
            }
            div:last-child {
              color: #111111;
              font-size: 1.4rem;
            }
          }
        }
      }
      .disabled-class {
        opacity: 0.5;
      }
    }
    .highlighted-text-section {
      display: flex;
      flex-direction: row;
    }
    .bold-text {
      font-weight: bold;
      color: #1aa99c;
    }
  }
}

.mobileView {
  #profile-container {
    height: auto;
    overflow: unset;
    .profile-header {
      .save-changes {
        margin-bottom: 0.8rem;
      }
    }

    .filter-section {
      .tab-section {
        width: 100%;
        .navigatorSection {
          width: 100%;
          .navigatorTabs {
            width: 100%;
            .tabs {
              width: 100%;
              text-align: center;
              padding: 1.05rem 1rem;
            }
          }
        }
      }
    }

    .content {
      border: none;
      padding: 0;
      flex-wrap: wrap;
      &.profile-content {
        min-height: auto;
        .full-width-savebtn {
          width: 100%;
          display: block;
        }
        .save-changes,
        .new-navigator-button {
          width: 100%;
          font-family: "Mon-medium", sans-serif;
          margin: 2.4rem 0 0.8rem;
          height: 4.8rem;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 2rem;
        }
      }

      #date-picker-container {
        width: 100%;
        right: auto;
        left: auto;
        top: 5rem;
        margin: 0;
      }

      &.vacation-mode {
        padding: 4rem 0;
        height: 100%;
        .vacation-container {
          overflow: unset;
        }
      }

      .backup-navigator-dropdown-container-profile {
        width: 100%;
        min-width: unset;
      }
      .input-section {
        margin-left: 0;
        padding: 0;
        margin-top: 3rem;
        .input-field {
          min-height: 4.8rem;
        }
        .input-plus-lens {
          .input-field {
            min-height: auto;
          }
        }
        .dropdown-container {
          .select-span {
            height: 4.8rem;
            .selected-value {
              font-size: 1.6rem;
            }
          }
          .option-box-wrapper {
            top: 4.8rem;
            height: auto;
          }
        }
        .backup-navigator-dropdown-container-profile {
          .backup-navigator-dropdown {
            height: 100%;
          }
        }
        .work-hours-container-group {
          margin-bottom: 2.4rem;
          @media (max-width: $mobile) {
            width: 100%;
          }
        }
        & > div {
          flex-wrap: wrap;
          margin-bottom: 2.4rem;
          &:last-child {
            margin-bottom: 0;
          }
          & > .text-input-field-container {
            margin-bottom: 2.4rem !important;
            width: 100%;
            .field-title {
              margin-top: 0;
            }
          }
        }
        .width30 {
          width: 100%;
        }
        .width65 {
          width: 100%;
        }
        .phone-container {
          width: 100%;
          margin-bottom: 2.4rem;
        }
        .work-hours-container {
          flex-wrap: wrap;
          margin-bottom: -2.4rem;
          &-group {
            & label {
              @media (max-width: $mobile) {
                min-width: 38px;
              }
            }
            & input {
              &:last-child {
                white-space: normal;
                padding: 0;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
        .vacation-date-container {
          width: 100%;
          flex-wrap: wrap;
          & > div {
            width: 100%;
          }
          .on-off-toggle {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            margin-bottom: 2.4rem;
            .toggleContainer {
              margin-top: 0;
            }
            .switch {
              width: 4rem;
              height: 2rem;
            }
            .slider:before {
              height: 1.6rem;
              width: 1.6rem;
            }
            & > .dropdown-label {
              margin-bottom: 0;
              margin-top: 0;
              margin-right: 1rem;
            }
          }
        }
        .navigator-dropdown-container {
          .navigator-dropdown {
            width: 100%;
          }
        }
        .time-zone-dropdown-container {
          .time-zone-selection {
            height: 4.8rem;
            margin-top: 0;
          }
          .time-zone-dropdown {
            width: 100%;
          }
        }
      }
    }
  }
}
