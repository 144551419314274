#episode-details-container {
  &.episode-details-container-with-patient-declined-alert {
    .plan-of-care-not-found {
      height: calc(100vh - 41rem);
    }
    .plan-of-care-table-body {
      height: calc(100vh - 47rem);
    }
  }
  .plan-of-care-not-found {
    width: 100%;
    height: calc(100vh - 38rem);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &-content {
      max-width: 26rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      & img {
        width: 21.4182rem;
        height: 19.394rem;
      }
    }
    &-heading,
    &-text {
      font-size: 1.4rem;
      line-height: 1.7rem;
      font-size: 1.4rem;
    }
    &-text {
      font-family: "Mon-medium", sans-serif;
      text-align: center;
      max-width: 22rem;
      margin-top: 0.7rem;
    }
    &-heading {
      margin-top: 1.5rem;
      font-family: "Mon-bold", sans-serif;
    }
  }
  .plan-of-care-table {
    &-header {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100%;
      background: #f1faf9;
      border-radius: 0.8rem 0.8rem 0 0;
      box-shadow: 0 0 0 0.1rem #bcdfef;
      color: #333;
      z-index: 2;
      .column {
        display: flex;
        box-sizing: border-box;
        padding: 1.6rem;
        align-items: center;
        word-break: break-word;
        box-sizing: border-box;

        &:nth-child(1) {
          min-width: 26rem;
        }
        &:nth-child(2),
        &:nth-child(3) {
          min-width: 15.8rem;
        }

        &:nth-child(4) {
          width: 100%;
          min-width: 16rem;
        }
        &:last-child {
          min-width: 16rem;
        }
        .column-content {
          display: flex;
          align-items: center;
          &.cursor-pointer {
            cursor: pointer;
          }
          &.disabled {
            cursor: not-allowed;
          }
          .content {
            margin-right: 0.8rem;
            font-family: Mon-bold, sans-serif;
            font-size: 1.4rem;
          }

          &-disable-cursor {
            cursor: not-allowed;
          }
        }
      }
    }
    &-body {
      background-color: #fff;
      box-sizing: border-box;
      display: block;
      height: calc(100vh - 44rem);
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      border: 0.1rem solid #eef0f6;
      border-radius: 0.8rem;
      border-top-left-radius: 0rem;
      border-top-right-radius: 0rem;
      .plan-of-care-table-row {
        display: flex;
        flex-direction: row;
        transition: background-color 0.1s ease-in-out;
        box-shadow: 0 0.1rem 0 0 #eef0f6;
        &.hovered {
          background-color: #eeeeee;
        }
        .column {
          box-sizing: border-box;
          word-break: break-word;
          padding: 1.6rem;
          display: flex;
          font-family: "Mon-medium", sans-serif;
          flex-direction: column;
          line-height: 1.7rem;
          font-size: 1.4rem;

          &:nth-child(1) {
            min-width: 26rem;
            max-width: 26rem;
            gap: 0.5rem;
            .service-name {
              font-family: "Mon-semi-bold", sans-serif;
              font-size: 1.4rem;
              color: #00887c;
            }
            .service-provider,
            .service-los {
              font-size: 1.2rem;
              color: #333;
              span {
                text-transform: capitalize;
                margin-right: 0.4rem;
                color: #999;
              }
            }
          }
          &:nth-child(2),
          &:nth-child(3) {
            min-width: 15.8rem;
          }
          &:nth-child(4) {
            width: 100%;
            display: flex;
            justify-content: start;
            padding: 1.6rem;
            line-height: 19px;
            font-size: 1.4rem;
            gap: 0.5rem;
            min-width: 17rem;
            .poc-details-row {
              display: flex;
            }
            .name {
              min-width: 16.2rem;
              width: 16.2rem;
              color: #999999;
              font-family: "Mon-semi-bold", sans-serif;
            }
          }
          .content {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            line-height: 1.7rem;
          }
          &:last-child {
            min-width: 16rem;
            width: 16rem;
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: start;
            justify-content: end;

            & > .icon {
              width: 3.2rem;
              height: 3.2rem;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              background-color: #ffffff;
            }
          }
        }
      }
    }
  }
}
