.preview {
  border: 1px solid #bcdfef;
  background-color: #f1faf9;
}
.sortable-row {
  background: #fff;
  border-bottom: 1px solid #ececec;
  padding: 1.1rem 0.8rem;
  min-height: 5rem;
  cursor: move;

  &.cursor-not-allowed {
    cursor: not-allowed;
  }
  .row-content {
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: 4.1rem 14rem 35rem 1.5fr 1fr 4rem;
    box-sizing: border-box;
    justify-items: start;
    color: #333;
    text-overflow: ellipsis;
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    align-items: center;

    .drag-icon {
      align-self: center;
      justify-self: center;
      user-select: none;
    }
    .services,
    .los {
      user-select: none;
    }
    .provider {
      display: flex;
      flex-direction: column;
      row-gap: 0.4rem;
      user-select: none;
      &-name {
        padding-right: 10px;
      }
    }

    .pref-priv-images {
      display: flex;
      column-gap: 0.5rem;
      height: 1.6rem;
      align-items: center;
      div {
        display: flex;
      }
      .distance-container {
        color: #636d70;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
        margin-left: 0.4rem;
        .distance {
          font-family: "Mon-bold", sans-serif;
          padding-left: 0.5rem;
        }
      }
    }

    .edit-button {
      display: flex;
      align-items: baseline;
      justify-self: flex-end;
      z-index: 2;
      cursor: pointer;
      svg {
        padding-right: 0.8rem;
      }
    }
  }

  &.animate {
    transition: all 0.1s;
    z-index: 1;
    border: 1px dashed #c1c9d2;
    opacity: 0.8;
    background: #f1f1f1;
  }

  &.opacity-1 {
    opacity: 1;
  }

  &.opacity-04 {
    opacity: 0.4 !important;
    pointer-events: none;
  }
}
