.add-service-popup {
  .modal-main {
    border-radius: 0.8rem;
  }
}
.add-service-container {
  display: flex;
  flex-direction: column;
  width: 41.8rem;
  background: #ffffff;
  border: 0.1rem solid rgba(51, 51, 51, 0.2);
  border-radius: 0.8rem;
  .header {
    font-family: "Mon-bold", sans-serif;
    font-size: 1.6rem;
    padding: 2.4rem 0;
    color: #333333;
    align-self: center;
  }
  .content {
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    color: #333333;
    padding: 0rem 2.5rem;

    .result-dropdown-container {
      margin-bottom: 1.6rem;
    }

    .no-pointer-events {
      cursor: not-allowed;
      pointer-events: all;
      .icon-container {
        svg {
          cursor: not-allowed;
        }
      }
    }

    .heading {
      font-family: "Mon-semi-bold", sans-serif;
      font-size: 1.2rem;
      margin-bottom: 0.4rem;
      color: #333333;
    }
    #single-date-picker-selector {
      width: fit-content;
    }
    .error-message {
      color: #ff5757;
      display: flex;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.2rem;
      line-height: normal;
      margin-top: 0.4rem;
    }
    .visits-los {
      margin: 1.6rem 0rem;
    }
    .acute-los {
      display: flex;
      align-items: center;
      column-gap: 0.8rem;
    }
    .acute-los-input {
      border-radius: 0.8rem;
      border: 0.1rem solid #acc0bb;
      opacity: 0.8;
      background-color: #fff;
      display: flex;
      width: 9rem;
      height: 3.9rem;
      padding: 0 1.6rem;

      &.error {
        border: 1px solid #ff5757;
      }

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
  .button-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2.4rem 2.5rem 1.9rem 2rem;
    .delete-button {
      cursor: pointer;
    }
    .cancel-button {
      font-family: "Mon-medium", sans-serif;
      color: #333333;
      padding: 1rem;
      width: 12.2rem;
      height: 4rem;
      font-size: 1.6rem;
      cursor: pointer;
      margin-right: 0.8rem;
    }
    .reload-button {
      font-size: 1.6rem;
      width: 12.2rem;
      height: 4rem;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
