.toc-reason-modal {
  .modal-main {
    width: 41.8rem;
    padding: 1.9rem 2.5rem;
    border-radius: 0.8rem;
    border: 0.1rem solid rgba(51, 51, 51, 0.2);
  }
  .header {
    color: #333;
    text-align: center;
    font-family: "Mon-bold", sans-serif;
    font-size: 1.6rem;
  }
  .toc-reason-content {
    .toc-reason-content-sub-heading {
      color: #333;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      margin: 2.4rem 0rem;
    }
  }
  .heading {
    color: #333;
    font-family: "Mon-semi-bold", sans-serif;
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
  }
  .reason-dropdown-container {
    width: 100%;
  }
  .button-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.4rem;
    .cancel-button {
      font-family: "Mon-medium", sans-serif;
      color: #333333;
      padding: 1rem;
      width: 12.2rem;
      height: 4rem;
      font-size: 1.6rem;
      cursor: pointer;
      margin-right: 0.8rem;
    }
    .submit-button {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.6rem;
      width: 12.2rem;
      height: 4rem;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
