.sortable-container {
  width: 100%;
  margin-bottom: 1.7rem;

  .sortable-header {
    display: grid;
    grid-template-columns: 4.1rem 14rem 35rem 1fr;
    justify-items: start;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    color: #333;
    font-family: "Mon-bold", sans-serif;
    font-size: 1.4rem;
    line-height: normal;
    border-bottom: 0.1rem solid #bcdfef;
    box-shadow: 0 0 0 0.1rem #bcdfef;
    background: #f7fcfb;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 1rem 1rem 0 0;
    padding: 1.1rem 0.8rem;
  }

  .sortable-row-container {
    width: 100%;
  }

  .legend-description {
    height: 4.2rem;
    display: flex;
    align-items: center;
    background: #fff;
    border-bottom: 0.1rem solid #ececec;
    border-left: 0.1rem solid #ececec;
    transform: translateX(-1px);
    width: 100%;

    .preferred-legend {
      display: flex;
      align-items: center;
      margin-left: 1.6rem;
      margin-right: 1.6rem;
      column-gap: 0.8rem;
      &-text {
        color: #333;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
      }
    }
    .privileged-legend {
      display: flex;
      align-items: center;
      column-gap: 0.8rem;
      &-text {
        color: #333;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
      }
    }
  }
}
