@import "shared/assets/scss/variables.scss";

.toc-row {
  display: flex;
  background: #ffffff;
  padding: 1.5rem;
  font-family: "Mon-medium", sans-serif;
  font-size: 14px;
  align-items: center;
  border-bottom: 1px solid #eef0f6;

  .toc-cell {
    .approved-date,
    .created-date {
      font-size: 1.4rem;
    }
    .approved-by,
    .created-by {
      font-size: 1.2rem;
      color: #636d70;
      word-break: break-word;
    }
    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 20%;
    }
  }
  .pending-status {
    color: #b91d1d;
  }
  .icons {
    width: 25%;
    display: flex;
    justify-content: end;
    .icon-background {
      margin-right: 16px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: 50%;
      &:hover {
        cursor: pointer;
      }
    }
    .edit-button {
      margin-right: 10px !important;
    }
  }
  &:hover {
    background: #eeeeee;
  }
}

.toc-approve-info {
  display: flex;
  flex-flow: column;
  padding: 2.4rem 3.2rem;
  background: #eeeeee;
  .toc-plan-error {
    color: #ff5757;
    font-family: "Mon-medium", sans-serif;
    font-size: 1.2rem;
    height: 3rem;
    transform: translateY(-0.5rem);
    span {
      display: flex;
      margin-bottom: 5px;
      img {
        margin-right: 6.5px;
        height: 15px;
        width: 15px;
      }
    }
  }
  .location-grid-container {
    width: 100%;
    margin-top: 0.86rem;
    margin-bottom: 1.6rem;
    .location-dropdowns {
      .toc-empty-image {
        width: 100%;
        height: 22.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0.8rem;
        background: #fff;
        margin-bottom: 1.6rem;

        .empty-message-container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-top: 1.5rem;
          .empty-message {
            color: #333;
            font-family: "Mon-bold", sans-serif;
            font-size: 1.4rem;
            margin-bottom: 0.7rem;
          }
          .empty-message-subline {
            color: #333;
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
          }
        }
      }
      .location-row-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1.7rem;
        align-items: center;
        font-size: 1.2rem;
        font-family: "Mon-bold", sans-serif;
        .location {
          width: 7rem;
        }

        .location-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-left: 2.5rem;
          margin-right: 2.5rem;
        }

        .preferred {
          color: #333;
          font-family: "Mon-medium", sans-serif !important;
          font-size: 1.2rem;
        }

        .los {
          width: 10rem;
          display: flex;
          justify-content: center;
        }
      }
      .read-only-footer {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 2rem;
        .preferred-text {
          color: #333;
          font-family: "Mon-medium", sans-serif;
          font-size: 12px;
        }
        width: 100%;
        .preferred-container {
          display: flex;
          gap: 0.8rem;
          align-items: center;
        }
      }
      .location-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.7rem;
        .location-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          align-items: center;
          width: 100%;
          .location-value {
            font-size: 1.4rem;
            color: #636d70;
            font-family: "Mon-medium", sans-serif;
            width: 7rem;
          }
          .toc-dropdown {
            width: 100%;
            margin-left: 2.5rem;
            margin-right: 2.5rem;
          }
          .toc-visits {
            margin-right: 2px;
            .visits-input {
              background: #ffffff;
              mix-blend-mode: normal;
              opacity: 0.8;
              border: 1px solid #c1c9d2;
              border-radius: 8px;
              height: 4.2rem;
              text-align: center;
              width: 60px;

              &:disabled {
                opacity: 0.5;
                border: 1px solid #c1c9d2;
                cursor: not-allowed;
              }
            }
            .input-error {
              border: 1px solid #ff5757;
            }
          }
        }
        .location-error {
          width: 100%;
          span {
            margin-top: 9px;
            color: #ff5757;
            font-family: "Mon-medium", sans-serif;
            display: flex;
            align-items: center;
            font-size: 12px;
            img {
              width: 15px;
              margin-right: 7px;
            }
          }
        }
      }
    }
    .checkbox-container {
      width: fit-content;
    }
  }

  .note-container {
    display: flex;
    flex-direction: column;
    order: 1;
    width: 60%;
    .revision-reasons {
      display: flex;
      justify-content: space-between;
    }
    .navigator-notes,
    .revision-reason,
    .additinal-notes {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      .note-heading {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        font-family: "Mon-bold", sans-serif;
      }
      .value {
        width: 100%;
        max-height: 12rem;
        overflow-y: auto;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
        color: #333333;
        word-break: break-all;
        line-height: 1.4rem;
      }
      .note-textarea {
        background: #ffffff;
        mix-blend-mode: normal;
        opacity: 0.8;
        border: 1px solid #c1c9d2;
        border-radius: 8px;
        height: 12rem;
        resize: none;
        padding: 12px 16px;
        font-family: "Mon-medium", sans-serif;
        color: #333333;
        line-height: 1.4rem;
      }
      .note-hint {
        color: #636d70;
        font-family: "Mon-medium", sans-serif;
        text-align: end;
        font-size: 1.2rem;
        margin-top: 0.5rem;
        .note-limit {
          font-family: "Mon-bold", sans-serif;
        }
      }
    }
  }

  .toc-plan {
    width: 100%;
    .care-plan-options-dropdown {
      width: 28rem;
      margin-bottom: 0.8rem;
    }
    .options-heading {
      font-family: "Mon-semi-bold", sans-serif;
      font-size: 1.2rem;
      color: #000;
      margin-bottom: 0.4rem;
    }
    .option-box-wrapper {
      z-index: 3;
    }
    .toc-add-service-with-options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add-service-button {
        width: 12.9rem;
        height: 4rem;
        color: #333;
        text-align: center;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.6rem;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.4;
        }
      }

      .toc-plan-los-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 0.8rem;

        .toc-plan-los {
          display: flex;
          align-items: center;
          flex-direction: row;
        }

        .acute-los-heading {
          font-family: "Mon-semi-bold", sans-serif;
          font-size: 1.2rem;
          color: #636d70;
          margin-right: 2.5rem;
          margin-bottom: 0.4rem;
          color: #000;
        }

        .acute-los {
          .actue-los-input {
            background: #ffffff;
            mix-blend-mode: normal;
            opacity: 0.8;
            border: 0.1rem solid #c1c9d2;
            border-radius: 0.8rem;
            height: 4.125rem;
            text-align: center;
            width: 6rem;

            &.input-error {
              border: 0.1rem solid #ff5757;
            }
          }
        }
      }
    }
    .episode-details {
      .toc-heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.6rem;
        .customise-care-plan-button {
          width: 20.1rem;
          height: 4rem;
          color: #333;
          text-align: center;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.6rem;

          &:disabled {
            opacity: 0.4;
            cursor: not-allowed;
          }
        }
      }
      .toc-plan-heading {
        font-size: 1.8rem;
        font-family: "Mon-bold", sans-serif;
        margin-bottom: 2.4rem;
      }
      .toc-plan-options {
        display: flex;
        align-items: center;
        gap: 0.8rem;
      }
      .toc-plan-los-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0.8rem;

        .toc-plan-los {
          display: flex;
          align-items: center;
          flex-direction: row;
        }

        .acute-los-heading {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.2rem;
          color: #636d70;
          margin-right: 2.5rem;
        }

        .acute-los {
          .actue-los-input {
            background: #ffffff;
            mix-blend-mode: normal;
            opacity: 0.8;
            border: 1px solid #c1c9d2;
            border-radius: 8px;
            height: 41.25px;
            text-align: center;
            width: 60px;

            &.input-error {
              border: 0.1rem solid #ff5757;
            }
          }
        }

        .acute-los-readonly {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.4rem;
          color: #000000;
          display: flex;
          justify-content: end;
        }
      }
    }
  }
}

.toc-approve-info-readonly {
  display: flex;
  flex-flow: column;
  padding: 2.4rem 3.2rem;
  background: #eeeeee;
  .note-episode-container {
    min-width: 25rem;
    width: 45%;
    margin-top: 2rem;
    margin-right: 6rem;
    margin-bottom: 2rem;
  }
  .note-container {
    display: flex;
    flex-direction: column;
    .revision-reasons {
      display: flex;
      justify-content: space-between;
    }
    .revision-reason {
      width: 45%;
      margin-top: 2rem;
      .note-heading {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        font-family: "Mon-bold", sans-serif;
      }
      .value {
        width: 100%;
        max-height: 12rem;
        overflow-y: auto;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
        color: #333333;
        word-break: break-all;
        line-height: 1.4rem;
      }
    }
    .external-notes {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 2rem;
      width: 100%;
      .navigator-notes,
      .physician-notes {
        width: 45%;
        .note-heading {
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
          font-family: "Mon-bold", sans-serif;
        }
        .value {
          width: 100%;
          max-height: 12rem;
          overflow-y: auto;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.2rem;
          color: #333333;
          word-break: break-all;
          line-height: 1.4rem;
        }
        .note-textarea-readonly {
          width: 100%;
          max-height: 12rem;
          overflow-y: auto;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.2rem;
          color: #333333;
          word-break: break-all;
          line-height: 1.4rem;
        }
      }
    }
    .internal-notes {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 2rem;
      width: 45%;
      .note-heading {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        font-family: "Mon-bold", sans-serif;
      }
      .note-textarea-readonly {
        width: 100%;
        max-height: 12rem;
        overflow-y: auto;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
        color: #333333;
        word-break: break-all;
        line-height: 1.4rem;
      }
    }
  }
  .toc-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .toc-plan {
      width: 100%;
      margin-bottom: 3rem;
      .care-plan-options-dropdown {
        width: 28rem;
      }
      .options-heading {
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 1.2rem;
        color: #000;
        margin-bottom: 0.4rem;
      }
      .option-box-wrapper {
        z-index: 3;
      }
      .toc-add-service-with-options {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .add-service-button {
          width: 12.9rem;
          height: 4rem;
          color: #333;
          text-align: center;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.6rem;
        }

        .toc-plan-los-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 0.8rem;

          .toc-plan-los {
            display: flex;
            align-items: center;
            flex-direction: row;
          }

          .acute-los-heading {
            font-family: "Mon-semi-bold", sans-serif;
            font-size: 1.2rem;
            color: #636d70;
            margin-right: 2.5rem;
            margin-bottom: 0.4rem;
          }

          .acute-los {
            .actue-los-input {
              background: #ffffff;
              mix-blend-mode: normal;
              opacity: 0.8;
              border: 0.1rem solid #c1c9d2;
              border-radius: 0.8rem;
              height: 4.125rem;
              text-align: center;
              width: 6rem;

              &.input-error {
                border: 0.1rem solid #ff5757;
              }
            }
          }

          .acute-los-readonly {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            color: #000000;
            display: flex;
            justify-content: end;
          }
        }
      }
      .episode-details {
        .toc-plan-heading {
          font-size: 1.8rem;
          font-family: "Mon-bold", sans-serif;
          margin-bottom: 2.4rem;
        }
        .toc-heading {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1.6rem;
          .customise-care-plan-button {
            width: 20.1rem;
            height: 4rem;
            color: #333;
            text-align: center;
            font-family: "Mon-medium", sans-serif;
            font-size: 1.6rem;
            &:disabled {
              opacity: 0.4;
              cursor: not-allowed;
            }
          }
        }
        .toc-plan-options {
          display: flex;
          align-items: center;
          gap: 0.8rem;
        }
        .toc-plan-los-container {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 0.8rem;
          .acute-los-heading {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.2rem;
            color: #636d70;
          }
          .acute-los-heading-sep {
            margin: 0 0.8rem;
          }
          .acute-los {
            .actue-los-input {
              background: #ffffff;
              mix-blend-mode: normal;
              opacity: 0.8;
              border: 1px solid #c1c9d2;
              border-radius: 8px;
              height: 41.25px;
              text-align: center;
              width: 60px;
            }
            .input-error {
              border: 1px solid #ff5757;
            }
          }

          .acute-los-readonly {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            color: #000000;
            display: flex;
            justify-content: end;
          }
        }
      }
      .toc-plan-error {
        color: #ff5757;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        height: 3rem;
        span {
          display: flex;
          margin-bottom: 1rem;
          img {
            margin-right: 6.5px;
            height: 15px;
            width: 15px;
          }
        }
      }
    }
    .location-grid-container {
      min-width: 25rem;
      width: 100%;
      margin-top: 0.86rem;
      margin-bottom: 1.6rem;
      .location-dropdowns {
        .location-row-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 1.7rem;
          align-items: center;
          font-size: 1.2rem;
          font-family: "Mon-bold", sans-serif;
          .location {
            width: 7rem;
          }

          .location-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-left: 2.5rem;
          }

          .preferred {
            color: #333;
            font-family: "Mon-medium", sans-serif !important;
            font-size: 1.2rem;
          }
        }
        .read-only-footer {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 2rem;
          .preferred-text {
            color: #333;
            font-family: "Mon-medium", sans-serif;
            font-size: 12px;
          }
          width: 100%;
          .preferred-container {
            display: flex;
            gap: 0.8rem;
            align-items: center;
          }
        }
        .location-row {
          display: flex;
          flex-direction: row;
          margin-bottom: 1.7rem;
          .location-value {
            font-size: 1.4rem;
            color: #636d70;
            font-family: "Mon-medium", sans-serif;
            width: 7rem;
          }
          .toc-dropdown-readonly {
            color: #333333;
            font-size: 1.2rem;
            font-family: "Mon-medium", sans-serif;
            margin-left: 2.5rem;
            display: flex;
            width: 100%;
            column-gap: 0.8rem;
            min-width: 67%;
          }
          .toc-visits-readonly {
            color: #333333;
            font-size: 1.2rem;
            font-family: "Mon-medium", sans-serif;
            margin-left: 9.5rem;
            display: flex;
          }
        }
      }
      .checkbox-container {
        .checkboxes {
          .checkbox-label {
            cursor: auto;
            .checkbox {
              cursor: auto;
            }
          }
        }
      }
      .view-changes-container {
        margin-top: 1rem;
        .cta {
          font-family: "Mon-medium", sans-serif;
          color: #00887c;
          font-size: 12px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.last-row-with-approve-button {
  height: 6.4rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: end;
  background: #eeeeee;
  padding-right: 32px;

  .cta-container {
    .clear-button,
    .cancel-button {
      font-family: "Mon-medium", sans-serif;
      color: #333333;
      padding: 1rem;
      width: 12.2rem;
      height: 4rem;
      font-size: 1.6rem;
      cursor: pointer;
      margin: 0rem 1rem;
      &:disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .submit-button {
      height: 4rem;
      width: 14.6rem;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.6rem;
      line-height: 2rem;
      margin-left: 1rem;
      cursor: pointer;

      &:disabled {
        pointer-events: none;
      }
    }
  }
}

@media (max-width: 1400px) {
  .toc-approve-info {
    display: flex;
    flex-direction: column;
    padding: 2.4rem 3.2rem;
    background: #eeeeee;
    .toc-plan-error {
      color: #ff5757;
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
      height: 3rem;
      span {
        display: flex;
        margin-bottom: 5px;
        img {
          margin-right: 6.5px;
          height: 15px;
          width: 15px;
        }
      }
    }
    .location-grid-container {
      width: 100%;
      flex-basis: 100%;
      order: 1;
      margin-bottom: 2rem;
      margin-top: 0;
      .location-dropdowns {
        .location-row-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 1.7rem;
          align-items: center;
          font-size: 1.2rem;
          font-family: "Mon-bold", sans-serif;
          .location {
            width: 7rem;
          }

          .location-name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-left: 2.5rem;
            margin-right: 2.5rem;
          }

          .preferred {
            color: #333;
            font-family: "Mon-medium", sans-serif !important;
            font-size: 1.2rem;
          }

          .los {
            width: 10rem;
            display: flex;
            justify-content: center;
          }
        }
        .read-only-footer {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 2rem;
          .preferred-text {
            color: #333;
            font-family: "Mon-medium", sans-serif;
            font-size: 12px;
          }
          width: 100%;
          .preferred-container {
            display: flex;
            gap: 0.8rem;
            align-items: center;
          }
        }
        .location-row {
          display: flex;
          flex-direction: column;
          margin-bottom: 1.7rem;
          .location-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            align-items: center;
            width: 100%;
            .location-value {
              font-size: 1.4rem;
              color: #636d70;
              font-family: "Mon-medium", sans-serif;
              width: 7rem;
            }
            .toc-dropdown {
              width: 100%;
              margin-left: 2.5rem;
              margin-right: 2.5rem;
            }
            .toc-visits {
              margin-right: 2px;
              .visits-input {
                background: #ffffff;
                mix-blend-mode: normal;
                opacity: 0.8;
                border: 1px solid #c1c9d2;
                border-radius: 8px;
                height: 4.2rem;
                text-align: center;
                width: 60px;

                &:disabled {
                  opacity: 0.5;
                  border: 1px solid #c1c9d2;
                  cursor: not-allowed;
                }
              }
              .input-error {
                border: 1px solid #ff5757;
              }
            }
          }
          .location-error {
            width: 100%;
            span {
              margin-top: 9px;
              color: #ff5757;
              font-family: "Mon-medium", sans-serif;
              display: flex;
              align-items: center;
              font-size: 12px;
              img {
                width: 15px;
                margin-right: 7px;
              }
            }
          }
        }
      }
      .checkbox-container {
        width: fit-content;
      }
    }

    .note-container {
      display: flex;
      flex-direction: column;
      width: 75%;
      order: 2;
      flex: none;
      .revision-reasons {
        display: flex;
        justify-content: space-between;
      }
      .external-notes {
        .note-heading {
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
          font-family: "Mon-bold", sans-serif;
        }
        .note-textarea {
          background: #ffffff;
          mix-blend-mode: normal;
          opacity: 0.8;
          border: 1px solid #c1c9d2;
          border-radius: 8px;
          height: 12rem;
          resize: none;
          padding: 12px 16px;
          font-family: "Mon-medium", sans-serif;
          color: #333333;
          width: auto;
          line-height: 1.4rem;
        }
        .note-hint {
          color: #636d70;
          font-family: "Mon-medium", sans-serif;
          text-align: end;
          font-size: 1.2rem;
          margin-top: 0.5rem;
          .note-limit {
            font-family: "Mon-bold", sans-serif;
          }
        }
      }
    }

    .toc-plan {
      width: 100%;
      .care-plan-options-dropdown {
        width: 28rem;
      }
      .options-heading {
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 1.2rem;
        color: #000;
        margin-bottom: 0.4rem;
      }
      .option-box-wrapper {
        z-index: 3;
      }
      .toc-add-service-with-options {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .add-service-button {
          width: 12.9rem;
          height: 4rem;
          color: #333;
          text-align: center;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.6rem;
        }
        .toc-plan-los-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 0.8rem;

          .toc-plan-los {
            display: flex;
            align-items: center;
            flex-direction: row;
          }

          .acute-los-heading {
            font-family: "Mon-semi-bold", sans-serif;
            font-size: 1.2rem;
            color: #636d70;
            margin-right: 2.5rem;
            margin-bottom: 0.4rem;
          }

          .acute-los {
            .actue-los-input {
              background: #ffffff;
              mix-blend-mode: normal;
              opacity: 0.8;
              border: 0.1rem solid #c1c9d2;
              border-radius: 0.8rem;
              height: 4.125rem;
              text-align: center;
              width: 6rem;

              &.input-error {
                border: 0.1rem solid #ff5757;
              }
            }
          }

          .acute-los-readonly {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            color: #000000;
            display: flex;
            justify-content: end;
          }
        }
      }
      .episode-details {
        .toc-plan-heading {
          font-size: 1.8rem;
          font-family: "Mon-bold", sans-serif;
          margin-bottom: 2.4rem;
        }

        .toc-heading {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1.6rem;
          .customise-care-plan-button {
            width: 20.1rem;
            height: 4rem;
            color: #333;
            text-align: center;
            font-family: "Mon-medium", sans-serif;
            font-size: 1.6rem;
            &:disabled {
              opacity: 0.4;
              cursor: not-allowed;
            }
          }
        }
        .toc-plan-options {
          display: flex;
          align-items: center;
          gap: 0.8rem;
        }

        .toc-plan-los-container {
          .acute-los-heading {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.2rem;
            color: #636d70;
            margin-right: 2.5rem;
          }

          .acute-los {
            .actue-los-input {
              background: #ffffff;
              mix-blend-mode: normal;
              opacity: 0.8;
              border: 1px solid #c1c9d2;
              border-radius: 8px;
              height: 41.25px;
              text-align: center;
              width: 60px;
            }
            .input-error {
              border: 0.1rem solid #ff5757;
            }
          }

          .acute-los-readonly {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            color: #000000;
            display: flex;
            justify-content: end;
          }
        }
      }
      .toc-plan-error {
        color: #ff5757;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        height: 3rem;
        span {
          display: flex;
          margin-bottom: 5px;
          img {
            margin-right: 6.5px;
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }

  .toc-approve-info-readonly {
    display: flex;
    flex-flow: column;
    padding: 2.4rem 3.2rem;
    background: #eeeeee;
    .note-episode-container {
      min-width: 25rem;
      width: 45%;
      margin-top: 2rem;
      margin-right: 6rem;
      margin-bottom: 2rem;
    }
    .note-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
      .revision-reasons {
        display: flex;
        justify-content: space-between;
      }
      .revision-reason {
        width: 45%;
        .note-heading {
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
          font-family: "Mon-bold", sans-serif;
        }
        .value {
          width: 100%;
          max-height: 12rem;
          overflow-y: auto;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.2rem;
          color: #333333;
          word-break: break-all;
          line-height: 1.4rem;
        }
      }
      .navigator-notes,
      .physician-notes {
        width: 45%;
        .note-heading {
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
          font-family: "Mon-bold", sans-serif;
        }
        .note-textarea-readonly {
          width: 100%;
          max-height: 12rem;
          overflow-y: auto;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.2rem;
          color: #333333;
          word-break: break-all;
          line-height: 1.4rem;
        }
      }
    }
    .toc-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .toc-plan {
        width: 100%;
        margin-bottom: 3rem;
        .care-plan-options-dropdown {
          width: 28rem;
        }
        .options-heading {
          font-family: "Mon-semi-bold", sans-serif;
          font-size: 1.2rem;
          color: #000;
          margin-bottom: 0.4rem;
        }
        .option-box-wrapper {
          z-index: 3;
        }
        .toc-add-service-with-options {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .add-service-button {
            width: 12.9rem;
            height: 4rem;
            color: #333;
            text-align: center;
            font-family: "Mon-medium", sans-serif;
            font-size: 1.6rem;
          }

          .toc-plan-los-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 0.8rem;

            .toc-plan-los {
              display: flex;
              align-items: center;
              flex-direction: row;
            }

            .acute-los-heading {
              font-family: "Mon-semi-bold", sans-serif;
              font-size: 1.2rem;
              color: #636d70;
              margin-right: 2.5rem;
              margin-bottom: 0.4rem;
            }

            .acute-los {
              .actue-los-input {
                background: #ffffff;
                mix-blend-mode: normal;
                opacity: 0.8;
                border: 0.1rem solid #c1c9d2;
                border-radius: 0.8rem;
                height: 4.125rem;
                text-align: center;
                width: 6rem;

                &.input-error {
                  border: 0.1rem solid #ff5757;
                }
              }
            }

            .acute-los-readonly {
              font-family: "Mon-medium", sans-serif;
              font-size: 1.4rem;
              color: #000000;
              display: flex;
              justify-content: end;
            }
          }
        }
        .episode-details {
          .toc-plan-heading {
            font-size: 1.8rem;
            font-family: "Mon-bold", sans-serif;
            margin-bottom: 2.4rem;
          }
          .toc-heading {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.6rem;
            .customise-care-plan-button {
              width: 20.1rem;
              height: 4rem;
              color: #333;
              text-align: center;
              font-family: "Mon-medium", sans-serif;
              font-size: 1.6rem;
              &:disabled {
                opacity: 0.4;
                cursor: not-allowed;
              }
            }
          }

          .toc-plan-options {
            display: flex;
            align-items: center;
            gap: 0.8rem;
          }

          .toc-plan-los-container {
            display: flex;
            .acute-los-heading {
              font-family: "Mon-medium", sans-serif;
              font-size: 1.2rem;
              color: #636d70;
            }

            .acute-los {
              .actue-los-input {
                background: #ffffff;
                mix-blend-mode: normal;
                opacity: 0.8;
                border: 1px solid #c1c9d2;
                border-radius: 8px;
                height: 41.25px;
                text-align: center;
                width: 60px;
              }
              .input-error {
                border: 1px solid #ff5757;
              }
            }

            .acute-los-readonly {
              font-family: "Mon-medium", sans-serif;
              font-size: 1.4rem;
              color: #000000;
              display: flex;
              justify-content: end;
            }
          }
        }
        .toc-plan-error {
          color: #ff5757;
          font-family: "Mon-medium", sans-serif;
          font-size: 12px;
          height: 3rem;
          span {
            display: flex;
            margin-bottom: 1rem;
            img {
              margin-right: 6.5px;
              height: 15px;
              width: 15px;
            }
          }
        }
      }
      .location-grid-container {
        min-width: 25rem;
        width: 100%;
        margin-top: 0.86rem;
        margin-bottom: 1.6rem;
        .location-dropdowns {
          .location-row-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 1.7rem;
            align-items: center;
            font-size: 1.2rem;
            font-family: "Mon-bold", sans-serif;
            .location {
              width: 7rem;
            }

            .location-name {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              margin-left: 2.5rem;
            }

            .preferred {
              color: #333;
              font-family: "Mon-medium", sans-serif !important;
              font-size: 1.2rem;
            }

            .los {
              width: 9rem;
            }
          }
          .read-only-footer {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 2rem;
            .preferred-text {
              color: #333;
              font-family: "Mon-medium", sans-serif;
              font-size: 12px;
            }
            width: 100%;
            .preferred-container {
              display: flex;
              gap: 0.8rem;
              align-items: center;
            }
          }
          .location-row {
            display: flex;
            flex-direction: row;
            margin-bottom: 1.7rem;
            .location-value {
              font-size: 1.4rem;
              color: #636d70;
              font-family: "Mon-medium", sans-serif;
              width: 7rem;
            }
            .toc-dropdown-readonly {
              color: #333333;
              font-size: 1.2rem;
              font-family: "Mon-medium", sans-serif;
              margin-left: 2.5rem;
              display: flex;
              width: 67%;
            }
            .toc-visits-readonly {
              color: #333333;
              font-size: 1.2rem;
              font-family: "Mon-medium", sans-serif;
              margin-left: 2.5rem;
              display: flex;
            }
          }
        }
        .checkbox-container {
          .checkboxes {
            .checkbox-label {
              cursor: auto;
              .checkbox {
                cursor: auto;
              }
            }
          }
        }
      }
    }
  }
}
