@import "shared/assets/scss/variables.scss";

.dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .current-selction-container {
    background: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.8;
    border: 0.1rem solid #c1c9d2;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: row;
    width: auto;
    padding: 0.4rem 0.5rem;

    &.disabled {
      opacity: 0.5;
      border: 0.1rem solid #c1c9d2;
    }

    .current-selection {
      width: 100%;
      display: flex;
      padding-left: 1.2rem;
      align-items: center;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.2rem;
      color: #333333;
    }

    .placeholder {
      width: 100%;
      display: flex;
      padding-left: 1.2rem;
      align-items: center;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.2rem;
      color: #000;
      opacity: 0.4;
      outline: none;
      padding-right: 0.4rem;
    }

    .cancel-selection-container {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      .cancel-icon-container {
        height: 100%;
        display: flex;
        align-items: center;
        .search-cross-icon {
          cursor: pointer;
          opacity: 0.4;
        }
        .search-cross-icon-disabled {
          cursor: auto;
        }
      }
    }
    .dropdown-icon-container {
      width: 3.3rem;
      height: 3.2rem;
      background: #eeeeee;
      border-radius: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      flex-shrink: 0;
    }
    .dropdown-icon-container-disabled {
      cursor: auto;
    }
  }

  .options-container {
    background: #ffffff;
    mix-blend-mode: normal;
    width: 100%;
    position: absolute;
    top: 100%;
    border: 0.1rem solid rgba(172, 192, 187, 0.8);
    box-shadow: 0rem 0.4rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 0.8rem;
    z-index: 1;

    .search-container {
      width: 100%;
      display: flex;
      border-bottom: 0.1rem solid rgba(172, 192, 187, 0.8);
      align-items: center;
      .search-input-container {
        width: 100%;
        display: flex;
        padding: 1rem;
        padding-right: 0rem;
        align-items: center;
        .search-input {
          width: 100%;
          outline: none;
          border: none;
          border-radius: 0.8rem;
          padding-left: 1.2rem;

          &::placeholder {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.2rem;
            color: #000;
            opacity: 0.4;
          }
        }
      }

      .cancel-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.2rem;
        height: 3.2rem;
        margin-right: 1rem;
        .search-cross-icon {
          cursor: pointer;
        }
      }
    }

    .dropdown-options-container {
      max-height: 25rem;
      overflow-y: scroll;

      .dropdown-options-row {
        display: flex;
        align-items: center;
        padding: 1.25rem 1.5rem;
        font-family: "Mon-medium", sans-serif;
        cursor: pointer;
        &:hover {
          background: #f1faf9;
        }
        .name {
          color: #333333;
          font-size: 1.4rem;
          margin-right: 1.5rem;
          max-width: 75%;
        }
        .distance {
          font-size: 1.4rem;
          margin-left: auto;
          display: flex;
          .text {
            color: #333333;
            opacity: 0.6;
            width: max-content;
          }
          .check {
            margin-left: 1rem;
          }
        }
      }

      .no-option-row {
        display: flex;
        align-items: center;
        padding: 1.25rem 1.5rem;
        font-family: "Mon-medium", sans-serif;

        .name {
          color: #333333;
          font-size: 1.4rem;
          margin-right: 1.5rem;
          max-width: 70%;
        }
      }
    }
  }
  .bold-text {
    color: #1aa99c;
  }
  .input-error {
    border: 0.1rem solid #ff5757;
  }
}
